import { useContext, useEffect, useState } from "react"
import { AuthContext } from "./authCtx"
import {getUserDetails, logout} from "./api";
import { PostList } from "./posts";

export const Me = ()=>{
    const {token, setToken} = useContext(AuthContext);

    const [myDetails, setMyDetails] = useState({});

    const getMyDetails = ()=>{
        getUserDetails(token).then(data=> setMyDetails(data.user)).catch(err=>console.log(err));
    };

    const handleLogout = ()=>{
        logout(token).then(date=> setToken(null)).catch(err=> console.log(err));
    }

    useEffect(()=>{
        getMyDetails();
    },[])

    return <div>
        <h1>
            Hi, {myDetails?.first_name} {myDetails?.last_name}
        </h1>
        <p>
            Please confirm, you Email is <span style={{ background: '#eee'}}>  {myDetails?.email}  </span> and {myDetails.phone && myDetails.phone !== ""? <>Phone No is <span style={{ background: '#eee'}}>{myDetails?.phone}</span></>: <>you have not added any phone number </> } 
            <br/>
            <br/>
            <br/>
            Also is the current signature fine:  <br/>
            <div dangerouslySetInnerHTML={{ __html: myDetails?.signature }}></div>
        </p>
        {/* <PostList/> */}
        <button style={{padding: "5px 10px", border: "none", cursor:'pointer', background: '#999'}} type="button" onClick={handleLogout} > Logout </button>
    </div>
} 