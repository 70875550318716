import logo from './logo.svg';
import './App.css';
import { getToken } from "./api.js"
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from './authCtx.js';
import { LoginForm } from './loginform.js';
import {Me} from './me.js';
function App() {

  const {token, setToken} = useContext(AuthContext);

  useEffect(()=>{
    console.log(token);
    getToken().then(data=> setToken(data.token)).catch(err=>console.log(err))
  },[])
  return (
    <div>
      {token? <Me/>: <LoginForm/>}
    </div>
  );
}

export default App;
