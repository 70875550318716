import { useEffect, useState, useContext, useRef } from "react";
import { addPost, commentAdd, commentList, postList, commentDelete, editPost, deletePost } from './api';

import { AuthContext } from "./authCtx"


export const PostList = ()=>{
    const {token} = useContext(AuthContext);
    const [posts, setPosts] = useState([]);
    const [totalPost, setTotalPost] = useState(0);

    const getPostList=()=>{
        postList(token).then((postList)=>{
            setTotalPost(postList.total_posts);
            setPosts(postList.posts);
        })
    }

    const newPostAdded = (postDetails)=>{
        addPost(token, postDetails.headline, postDetails.body).then(()=>{ getPostList(); })
    }

    useEffect(()=>{
        getPostList();
    },[])

    return <div style={{display: 'flex', flexDirection: 'column'}}>
        <h1> Posts ({totalPost})</h1>
        { posts.map((post)=>{
            return <Post detail={post} key={post.post_id} reload={()=> getPostList()}/>
        }) }

        <PostForm formid="newpost" onSubmit={newPostAdded}/>
    </div>   
}


const PostForm = ({ formid, onSubmit, postDetail })=>{
    const headline = useRef();
    const body = useRef();
    const postForm = useRef();
    const handleFormSubmit = (e)=>{
        e.preventDefault();
        onSubmit({ 
            headline: headline.current.value,
            body: body.current.value,
        });
        postForm.current.reset();
    }

    useEffect(()=>{
        if(postDetail && headline.current && body.current){
            headline.current.value = postDetail.headline;
            body.current.value = postDetail.body;
        }
    },{postDetail, headline,body})

    return <form ref={postForm} onSubmit={handleFormSubmit}>
        <div>
            <label htmlFor={`${formid}-headline`}>Post Heading </label>
            <input ref={headline} style={{width: "90%", display:"block", padding:"5px"}}  name="headline" placeholder="Enter the heading" id={`${formid}-headline`} required></input>
        </div>
        <div>
            <label htmlFor={`${formid}-headline`}>Post Body</label>
            <textarea ref={body} cols="4" placeholder="Enter post body here..." name="body" style={{width: "90%", display:"block", padding:"5px"}} required></textarea>
        </div>
        <button type="submit" style={{padding: "5px 15px", cursor: "pointer",background: "#3ac1eb", border: "none", float:'right'}} > Submit</button>
    </form>
}

const Post = ({detail, reload})=>{
    const {token} = useContext(AuthContext);
    const [editMode, setEditMode] = useState(false);
    const [comments, setComments] = useState([]);
    const [totalCommnet, setTotalComment] = useState(0);

    const getComments=()=>{
        commentList(token, detail.post_id).then((commentList)=>{
            setTotalComment(commentList.total_comments);
            setComments(commentList.comments);
        })
    }


    const editPostDetail = (postDetails)=>{
        editPost(token, detail.post_id, postDetails.headline, postDetails.body).then(()=> setEditMode(!editMode)).finally(()=> reload())
    }

    const deleteP = ()=>{
        deletePost(token, detail.post_id).finally(()=>reload())
    }

    useEffect(()=>{
        getComments();
    },[detail])

    return <div style={{ background: "#e3e3e3", padding: "10px", margin: "15px 5px" }}>
        {detail.editable? <div style={{float:'right'}} >
            <button style={{padding: "5px 15px", cursor: "pointer",background: "#3ac1eb", border: "none"}} onClick={()=> setEditMode(!editMode)} > {editMode? "Reset": "Edit"} </button>
            <button style={{padding: "5px 15px", cursor: "pointer",background: "#eb3a41", border: "none"}} onClick={deleteP}> Delete</button>
            </div> : <></>}
        {editMode? <PostForm postDetail={detail} formid={`PostId-${detail.post_id}-edit`} onSubmit={editPostDetail}/>
        : <>
            <h3>{detail.headline}</h3>
            <p>{detail.body}</p>
            <p style={{fontSize: "small",color: "#494949", textAlign: "right"}} > -: {detail.first_name} {detail.last_name} ({detail.username}) </p>
        </>}
        

        <CommentList total={totalCommnet} comments={comments} post_id={detail.post_id} reload={getComments} />
    </div>
}

const CommentList = ({total, comments, post_id, reload})=>{
    
    const {token} = useContext(AuthContext);

    const handleComment = (comment)=>{
        commentAdd(token, post_id, comment.comment).then(()=> reload())
    }

    const handleDelete = (id)=>{
        commentDelete(token, post_id, id).then(()=> reload());
    }

    return <div style={{display: 'flex', flexDirection: 'column'}}>
        <h5> Comments ({total}) </h5>
        {comments.map((comment)=>{
            return <Comment detail={comment} key={comment.id} onDelete={handleDelete}/>
        })}
        <CommentForm formid={`Post-Id-Of-${post_id}-newcomment`} onSubmit={handleComment} />
    </div>
}

const Comment = ({detail, onDelete}) => {
    return <p style={{background: "#fff"}}> 
            {detail.comment}
            <span style={{fontSize: "small",color: "#494949"}}> -: {detail.first_name} {detail.last_name} ({detail.username})  </span>
            {detail.editable? <button style={{padding: "5px 15px", cursor: "pointer",background: "#eb3a41", border: "none", float:'right'}} onClick={()=> onDelete(detail.id)} > Delete</button> : <></>}
        </p>
}

const CommentForm = ({formid, commentValue, onSubmit})=>{
    const comment = useRef();
    const commentForm = useRef();
    const handleFormSubmit = (e)=>{
        e.preventDefault();
        onSubmit({ 
            comment: comment.current.value
        });
        commentForm.current.reset();
    }

    useEffect(()=>{
        if(commentValue && commentValue !== '' && comment.current){
            comment.current.value = commentValue;
        }
    },[commentValue, comment])

    return <form ref={commentForm} onSubmit={handleFormSubmit}>
        <div>
            <label htmlFor={`${formid}-headline`}>Comment Body</label>
            <textarea ref={comment} cols="4" placeholder="Enter post body here..." name="body" style={{width: "90%", display:"block", padding:"5px"}} required></textarea>
        </div>
        <button type="submit" style={{padding: "5px 15px", cursor: "pointer",background: "#3ac1eb", border: "none", float:'right'}} > Submit</button>
    </form>
}