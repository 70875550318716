import { useContext, useRef } from "react";
import { logIn } from "./api"
import { AuthContext } from "./authCtx";

export const LoginForm = ()=>{
    const {token, setToken} = useContext(AuthContext);
    const usernameInput = useRef();
    const passwordInput = useRef();

    const handelSubmit = (e)=>{
        e.preventDefault();
        const username = usernameInput?.current?.value || null;
        const password = passwordInput?.current?.value || null;
        logIn(username, password).then(data => {
            console.log(data);
            if(data.token){
                setToken(data.token);
            }
        })
    }
    return <form onSubmit={handelSubmit} >
        <div>
            <label htmlFor="username"> Username </label>
            <input ref={usernameInput}  type="text" placeholder="Username.." id="username" required/>
        </div>
        <div>
            <label htmlFor="username"> Password </label>
            <input ref={passwordInput} type="text" placeholder="Password.." id="username" required/>
        </div>
        <button type="submit">Submit</button>
    </form>
}