const base_url = "http://common-login.fastexpertdev.com/RESTAPI/"

export const getToken = ()=>{
    return new Promise((res, rej)=>{
        fetch(`${base_url}get_token.php`, {method: 'GET', credentials: 'include'}).then((resp)=>{
            resp.json().then(data=>{
                res(data);
            }).catch(err=> rej(err));
        }).catch(err=>rej(err));
    })
}

export const getUserDetails = (token)=>{
    return new Promise((res, rej)=>{
        fetch(`${base_url}me.php`, { method: 'GET', credentials: 'include', headers: { 'Authorization': `Bearer ${token}` }}).then((resp)=>{
            resp.json().then(data=>{
                res(data);
            }).catch(err=> rej(err));
        }).catch(err=>rej(err));
    })
}


export const postList = (token)=>{
    return new Promise((res, rej)=>{
        fetch(`${base_url}posts.php`, { method: 'GET', credentials: 'include', headers: { 'Authorization': `Bearer ${token}` }}).then((resp)=>{
            resp.json().then(data=>{
                res(data);
            }).catch(err=> rej(err));
        }).catch(err=>rej(err));
    })
}


export const commentList = (token, post_id)=>{
    return new Promise((res, rej)=>{
        fetch(`${base_url}comments.php?post_id=${post_id}`, { method: 'GET', credentials: 'include', headers: { 'Authorization': `Bearer ${token}` }}).then((resp)=>{
            resp.json().then(data=>{
                res(data);
            }).catch(err=> rej(err));
        }).catch(err=>rej(err));
    })
}

export const commentAdd = (token, post_id, comment)=>{
    return new Promise((res, rej)=>{
        fetch(`${base_url}comments.php`, { method: 'POST', credentials: 'include', headers: { 'Authorization': `Bearer ${token}` }, body: JSON.stringify({ post_id: post_id, comment: comment })  }).then((resp)=>{
            resp.json().then(data=>{
                res(data);
            }).catch(err=> rej(err));
        }).catch(err=>rej(err));
    })
}

export const commentDelete = (token, post_id, comment_id)=>{
    return new Promise((res, rej)=>{
        fetch(`${base_url}comments.php`, { method: 'DELETE', credentials: 'include', headers: { 'Authorization': `Bearer ${token}` }, body: JSON.stringify({ post_id: post_id, comment_id: comment_id })  }).then((resp)=>{
            resp.json().then(data=>{
                res(data);
            }).catch(err=> rej(err));
        }).catch(err=>rej(err));
    })
}

export const editPost = (token, post_id, headline,body)=>{
    return new Promise((res, rej)=>{
        fetch(`${base_url}posts.php`, { method: 'PUT',credentials: 'include', headers: { 'Authorization': `Bearer ${token}` }, body: JSON.stringify({ post_id: post_id, headline: headline, body: body }) }).then((resp)=>{
            resp.json().then(data=>{
                res(data);
            }).catch(err=> rej(err));
        }).catch(err=>rej(err));
    })
}

export const deletePost = (token,post_id)=>{
    return new Promise((res, rej)=>{
        fetch(`${base_url}posts.php?post_id=${post_id}`, { method: 'DELETE',credentials: 'include', headers: { 'Authorization': `Bearer ${token}` } }).then((resp)=>{
            resp.json().then(data=>{
                res(data);
            }).catch(err=> rej(err));
        }).catch(err=>rej(err));
    })
}


export const addPost = (token, headline,body)=>{
    return new Promise((res, rej)=>{
        fetch(`${base_url}posts.php`, { method: 'POST',credentials: 'include', headers: { 'Authorization': `Bearer ${token}` }, body: JSON.stringify({ headline: headline, body: body }) }).then((resp)=>{
            resp.json().then(data=>{
                res(data);
            }).catch(err=> rej(err));
        }).catch(err=>rej(err));
    })
}


export const logout = (token)=>{
    return new Promise((res, rej)=>{
        fetch(`${base_url}logout.php`, { method: 'GET', credentials: 'include', headers: { 'Authorization': `Bearer ${token}` }}).then((resp)=>{
            resp.json().then(data=>{
                res(data);
            }).catch(err=> rej(err));
        }).catch(err=>rej(err));
    })
}


export const logIn = (username, password)=>{
    return new Promise((res, rej)=>{
        fetch(`${base_url}login.php`, { method: 'POST', body: JSON.stringify({ username: username, password: password }) }).then((resp)=>{
            resp.json().then(data=>{
                res(data);
            }).catch(err=> rej(err));
        }).catch(err=>rej(err));
    })
}
